import React from 'react';
 
const Contact = () => {
    return (
       <div>
          <h1>Contact me</h1>
          <p>Feel free to contact me on ajinkyabhandare01@gmial.com</p>
       </div>
    );
}
 
export default Contact;