import React from 'react';
import "../styling/About.css"


const TITLE = 'About'

const About = () => {

   return (
      <div id='about'>
         <h1>About me</h1>
         <p> Hi! I am Ajinkya Bhandare</p>
            <img src='/my-image.jpeg' height={350} width={350} alt='Pic of ajinkya'/>
         <p> I graduated from IIT guwahati in 2022 with majors in Electronics and Communication Engineering. I am interested in working with robots, mainly on the problem of SLAM, computer vision etc.</p>
         <br></br>
         <p> I have been wokring since graduation in multiple tech startups as a software developer. In my free time, I like to read and watch F1. Feel free to reach out to me from the contact page or on any of the platforms below.</p>
         <div id='contact-links'>
            <a href="https://linkedin.com/in/ajinkyabhandare" target="_blank" rel="noreferrer">
               <img src='/linkedin-icon.png' width={20} height={20} alt='Linkedin'/>
            </a>
            <a href="https://github.com/Ajinkya-Bhandare" target="_blank" rel="noreferrer">
               <img src='/github-icon.jpeg' width={20} height={20} alt='Github'/>
            </a>
            <a href="mailto:ajinkyabhandare01@gmail.com" rel='noreferrer'>
            <img src='/mailto-icon.png' width={20} height={20} alt='Github'/>
            </a>
         </div>
      </div>
    );
}
 
export default About;