import React from 'react';
import "../styling/Home.css"
const home = () => {
    return (
       <div>
          <h1>Home</h1>
           <p>Hi. This page will give idea of about me</p>
       </div>
    );
}
 
export default home;